@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";


.accommodation {
  padding-top: 60px;
  -webkit-font-smoothing: subpixel-antialiased;
  //background-color: #fcf3e0;

}

.accommodation__title {
  display: flex;
  justify-content: center;

  h2 {
    text-align: center;
    color: black;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.23;
    margin: 0;
    padding-bottom: 10px;
  }
}

.accommodation__wrapper {
  display: flex;
  justify-content: center;
}

.accommodation__lead {

  opacity: .5;
  font-size: 20px;
  line-height: 1.55;
  padding-bottom: 10px;

}

.accommodation__types-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 40px;
}

.accommodation__types-item--first {
  padding-bottom: 20px;
}

.accommodation__info {
  display: flex;
  flex-direction: column;
}

.accommodation__info-title {
  color: black;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.23;
  margin: 0;
  text-align: center;
  padding-bottom: 15px;

}

.accommodation__capacity {
  opacity: .5;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.55;
  padding-bottom: 15px;
}

.accommodation__description {
  font-size: 20px;
  line-height: 1.55;
  font-weight: 300;
  padding-bottom: 20px;
}

.accommodation__button {
  color: #ffffff;
  background-color: #0071c2;
  //background-color: #2c75ff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;

  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;

  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 500;
  background-image: none;
  cursor: pointer;
  letter-spacing: 0.03em;
  width: 135px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.5);


  &:hover {
    color: white;
    text-decoration: none;
    background-color: #1d499e;

  }
}

.accommodation__carousel-item {
  img {
    object-fit: cover;
    height: 400px;
    display: block;
    //filter: brightness(90%);
  }

}

@media screen and (min-width: 768px) and (max-width: 992px) {
  
  .accommodation {
    padding-top: 30px;
  }

  .accommodation__carousel {
    padding-bottom: 20px;
  }

  .accommodation__description {
    font-size: 18px;
  }
  

}

@media screen and (min-width: 376px) and (max-width: 767px) {

  .accommodation {
    padding-top: 30px;
  }

  .accommodation__title h2 {

    font-size: 28px;
    text-align: center;
  }
  .accommodation__info {
    align-items: center;
    padding-bottom: 35px;
  }

  .accommodation__carousel {
    padding-bottom: 20px;
  }



}

@media screen and (max-width: 375px) {
  .accommodation {
    padding-top: 20px;
  }

  .accommodation__title h2 {
    font-size: 28px;
    text-align: center;
  }

  .accommodation__lead {
    text-align: center;
    font-size: 18px;
  }

  .accommodation__carousel {
    padding-left: 0;
    padding-right: 0;
  }

  .accommodation__types-item {
    padding-top: 0;
  }

  .accommodation__carousel-item {
    img {
      object-fit: cover;
      height: 300px;
      display: block;
      //filter: brightness(90%);
    }
  }

  .accommodation__types-item--first .accommodation__info {
    padding-top: 10px;
  }

  .accommodation__description {
    font-size: 18px;
  }

  .accommodation__info {
    align-items: center;
    padding-bottom: 35px;
  }
}