@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";

.zoo {
  padding-top: 30px;
  padding-bottom: 15px;
}

.zoo__textblock {
  -webkit-font-smoothing: antialiased;
  font-size: 25px;
  font-weight: 300;
  text-align: center;
}

.zoo__title {
  -webkit-font-smoothing: antialiased;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  padding-top: 20px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .zoo__textblock {
    font-size: 18px;
  }
}

@media screen and (max-width: 575px) {

  .zoo__textblock {
    font-size: 18px;
  }

  .zoo__title {
    font-size: 32px;
  }
}