@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";

.house {
  padding-top: 50px;
  padding-bottom: 50px;
}

.house__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

//.house__first, .house__second {
//  padding-left: 10px;
//  padding-right: 10px;
//}

.house__title {
  h2 {
    margin: 0;
    color: black;
    font-weight: 600;
    font-size: 35px;
    line-height: 1.23;
    text-align: center;
    padding-bottom: 15px;

  }
 }

.house__brief-description, .house__description {
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 300;

}

.house__description {
  display: flex;
  flex-direction: column;

  padding-top: 10px;

}

.house__furniture {
  display: flex;
  flex-direction: column;
}

.house__furniture-wrapper {
 display: flex;
  align-items: center;
}


.house__furniture-image {
  width: 45px;
  padding-left: 10px;
}

.house__list {
  margin: 0;
  padding-left: 20px;
  //-webkit-font-smoothing: subpixel-antialiased;
  //font-size: 18px;
  //line-height: 1.55;
  //font-weight: 300;
}
.house__header-left {
  padding-top: 10px;
  padding-bottom: 10px;
}
.house__header-right {
  padding-top: 30px;
  padding-bottom: 10px;
}

.house__carousel-item {
  img {
    object-fit: cover;
    height: 400px;
    display: block;
    //filter: brightness(90%);
  }
  }


.house__link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}


.house__order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.house__order-title {
  color: black;
  font-weight: 600;
  font-size: 35px;
  line-height: 1.23;
  text-align: center;
  padding-bottom: 15px;
}

.house__order-text {
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 22px;
  line-height: 1.55;
  font-weight: 300;
  text-align: center;
}

@media screen  and (max-width: 1200px) {

.house__furniture-image {
  display: none;
}
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .house__title h2 {
    font-size: 26px;
  }

  .house__description {
    font-size: 16px;
  }

  .house__order-title {
    font-size: 25px;
  }

  .house__order-text {
    font-size: 18px;
  }
}


@media screen and (min-width: 576px) and (max-width: 767px) {
  .house__carousel {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .house__order-text {
    font-size: 17px;
  }


}

@media screen and (max-width: 575px) {
  .house {
    padding-top: 20px;
  }

  .house__header-right {
    padding-top: 20px;
  }

  .house__title h2 {
    font-size: 28px;
  }

  .house__brief-description {
    font-size: 15px;
  }

  .house__description {
    font-size: 16px;
  }

  .house__carousel {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .house__carousel-item img {
    height: 300px;
  }

  .house__order-title {
    font-size: 25px;
  }

  .house__order-text {
    font-size: 18px;
  }
}