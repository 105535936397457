@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";

.about {
  padding-top: 60px;
  padding-bottom: 10px;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //background-color: #f3f3f3;
}

.about_wrapper {
  display: flex;
  justify-content: space-evenly;
}

.about__left, .about__right {
  //outline: 1px solid green;
}

.about__title {
  color: black;
  font-weight: 600;
  font-size: 48px;
  line-height: 1.23;
  margin: 0;
  padding-bottom: 20px;

}

.about__contacts {
  color: black;
  font-size: 16px;
  line-height: 1.55;
  font-weight: 700;
  padding-bottom: 15px;

}
.about__contacts-addr {
  //line-height: 1.55;
  opacity: .5;

}
.about__contacts-tel {
  color: black;
  opacity: .5;
  //font-size: 16px;
  //line-height: 1.55;
  &:hover {
    color: black;
    opacity: .5;
    text-decoration: none;
  }
}
.about__text {
  -webkit-font-smoothing: subpixel-antialiased;

  //color: black;
  //text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.2);
  //font-size: 25px;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 300;

}
.about__link, .about__link:hover {
  text-decoration: none;
  //color: blue;
}


.about__wrapper-bottom {
  outline:1px solid red;
  display: flex;
  justify-content: center;
}

.about__image {
  max-width:100%;
}

.showplace {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  //-webkit-font-smoothing: subpixel;

}
.showplace__title {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  //justify-content: flex-end;

  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 1.23;
    padding-bottom: 5px;
    margin: 0;
  }
}

.showplace__description {
  display: flex;
  justify-content: center;
  //justify-content: flex-end;
  color: black;
  font-size: 16px;
  line-height: 1.55;
  font-weight: 500;
  padding-bottom: 15px;
  opacity: 0.5;
}

.showplace__items {

}
.showplace__item {
  //color: black;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 3px;
  padding-bottom: 3px;
  //font-size: 25px;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;

}

.showplace__item-name {

}

.showplace__item-distance {
  padding-left: 20px;
}

//
// @media screen and (min-width: 577px) and (max-width: 767px) {}
//
// @media screen and (min-width: 376px) and (max-width: 576px) {}
//

@media screen and (min-width: 768px) and (max-width: 992px) {

.about__text {
  font-size: 15px;
}

  .showplace__item-name,.showplace__item-distance {
    font-size: 15px;
  }


}

 @media screen and (max-width: 375px) {

   .about {
     padding-top: 20px;
   }

   .about__title {
     font-size: 35px;
   }

   .showplace__title h3 {
     font-size: 25px;
   }

   .showplace__item {
     justify-content: space-between;
     font-size: 14px
   }
   .showplace__item-distance {
     padding-left: 0px;
   }
 }