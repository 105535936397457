@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";

.fishing-price {
  padding-bottom: 40px;
  -webkit-font-smoothing: subpixel;

}

.price__title {
  display: flex;
  justify-content: center;
  padding-top: 40px;


  h2 {
    color: black;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.23;
    margin: 0;
    text-align: center;
    padding-bottom: 15px;
  }

}

.title--small {
  font-size: 25px;
  padding-top: 0px;
  padding-bottom: 20px;
  
  h3 {
    text-align: center;
  }

}

.price__wrapper {
  display: flex;
  justify-content: center;
  padding-top: 0px;
}


.price__text {
  font-size: 20px;
  line-height: 1.55;
  font-weight: 300;
  padding-bottom: 20px;
  text-align: center;
}

.price__table {
  //padding-top: 30px;

  th {
    text-align: center;
  }
  td {
    text-align: center;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {

  .price__text {
    font-size: 18px;
  }
}

@media screen and (max-width: 575px) {

  .price_title {
    font-size: 26px;
  }

  .title--small {
    h3 {
    font-size: 22px;
  }
}

  .price__text {
    font-size: 16px;
  }

  .price__table {
    th {
      font-size: 14px;
    }
    td {
      font-size: 14px;
    }
  }

}