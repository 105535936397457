@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";

.fishing {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9), rgba(199, 226, 234, 0.95));
  //background-color: #c7e2ea;
  padding-top: 40px;
  padding-bottom: 30px;
  -webkit-font-smoothing: subpixel-antialiased;

}

.fishing__title {
  display: flex;
  justify-content: center;

  h2 {
    text-align: center;
  color: black;
  font-weight: 600;
  font-size: 48px;
  line-height: 1.23;
  margin: 0;
  padding-bottom: 20px;
  }
}

.fishing__wrapper {
  display: flex;
  justify-content: center;
}

.fishing__lead {
  text-align: center;
  font-weight: 300;
  //font-size: 18px;
  font-size: 25px;
  line-height: 1.37;

}

.fishing__image {
  width: 90%;
  max-width: 1053px;
  //min-width: 690px;
}

.fishing__image-wrapper {
  display: flex;
  justify-content: center;
}

.fishing__list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 10px;
}
.last-fish-list {
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.fishing__list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  padding-bottom: 10px;

}
.fishing__list-item-last {
  width: 50%;
}

.fishing__list-item-pic {

  width: 80px;
}

.som {
  width: 60px;
}



.fishing__list-item-fishname {
  padding-left: 15px;
  line-height: 1;
}

.som-text {
  padding-left: 35px;
}

.fishing__text-wrapper {
  display: flex;
  justify-content: center;
}

.fishing__text {
  font-size: 20px;
  line-height: 1.55;
  font-weight: 300;
  text-align: center;

}

.fishing__endtext {
  padding-top: 10px;
  font-size: 25px;
  line-height: 1.55;
  font-weight: 300;
  text-align: center
}


@media screen and (min-width: 768px) and (max-width: 992px) {
  .fishing__text {
    font-size: 15px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .fishing__lead {
    text-align: left;
    font-size: 20px;
  }

  .fishing__list-item-pic {
    width: 60px;
  }

  .fishing__list-item-fishname {
    font-size: 14px;
    padding-left: 10px;
  }

  .fishing__endtext {
    font-size: 20px;
  }
}

@media screen and (min-width: 376px) and (max-width: 991px) {
  .fishing__lead {
    text-align: center;
    font-size: 20px;
  }

  .fishing__endtext {
    font-size: 20px;
  }

}

@media screen and (max-width: 375px) {

  .fishing {
    padding-top: 20px;
  }

  .fishing__title {
    h2 {
      font-size: 28px;
      text-align: center;
    }
  }

  .fishing__lead {
    text-align: left;
    font-size: 18px;
  }

  .fishing__image {
    width: 100%;
    min-width: 320px;
  }

  .fishing__text-wrapper {
    flex-direction: column;
  }

  .fishing__text {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    text-align: left;
  }

  .fishing__endtext {
    font-size: 18px;
  }

  .fishing__list-item-pic {
    width: 60px;
  }

  .fishing__list-item-fishname {
    font-size: 14px;
    padding-left: 10px;
  }

}