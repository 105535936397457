@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";

.event {
  padding-top: 30px;
}

.event__text {
  font-size: 18px;
  font-weight: 300;
  color: black;
  text-align: center;
  padding-top: 15px;

  &--bold {
    font-size: 36px;
    font-weight: 700;
  }
}


.event__wrapper {
  display: flex;
  justify-content: center;
  padding: 30px 0;

}
.event__wrapper--inner {

  margin-left: 10px;
  margin-right: 10px;

}

.event__quote-text {
  float: right;
  max-width: 400px;
  margin-left: 30px;
  font-size: 36px;
  font-weight: 300;
  font-style: italic;
  line-height: 40px;

  &-little {
    padding-top: 10px;
    font-size: 30px;
    text-align: right;
  }
  &:before {
    content: '';
    display: block;
    height: 3px;
    background:  linear-gradient(135deg, deeppink 20%, rebeccapurple 70%);
    margin-top: 15px;
    margin-bottom: 16px;
    width: 40%;
  }
}


.event__main-text {
  margin: 0;
  float: none;
  font-size: 21px;
  //font-size: 20px;
  //font-size: 18px;
  font-weight: 300;
  line-height: 1.55;
}

.event__text-little {
  font-size: 19px;
}

.event__textblock {
  margin: 0;
  float: none;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.55;
  padding-top: 0;
}


.event__textblock-divider  {
  padding-top: 10px;
}

.event__description-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

.event__description {
  display: flex;
  flex-direction: column;
}

.event__description-wrapper-inner {
  padding-top: 10px;
}

.event__image {
  padding-top: 5px;
  img {
    width: 100%;
  }
}

.event__order {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 30px;
}

.event__order-title {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  h2 {
    margin: 0;
    color: black;
    font-weight: 600;
    font-size: 35px;
    line-height: 1.23;
    text-align: center;
    padding-bottom: 15px;
  }
}

.event__order-cta-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.event__order-cta-text {
  margin: 0;
  color: black;
  font-weight: 300;
  font-size: 30px;
  line-height: 1.23;
  text-align: center;
  padding-bottom: 15px;
}

.event__order-cta-link {
  display: flex;
  justify-content: center;

  a,a:hover {
    color: black;
    font-weight: 300;
    font-size: 25px;
    text-decoration: none;

  }
}

.event__carousel-itemv img {
  object-fit: contain;
  height: 400px;
  filter: brightness(100%);
}




.event-pictures {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 15px;

}

.event-pictures__wrapper-outer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.event-pictures__outer-item {
  display: flex;
  width: 47%;
  min-width: 280px;
  padding-bottom: 15px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.event-pictures__wrapper-inner {
  width: 50%;
  min-width: 280px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.event-pictures__inner-item {
  width: 100%;
  height: 48%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .event__quote-text {
    font-size: 20px;
  }
  .event__quote-text-little {
    font-size: 27px;
    padding-top: 5px;
  }
}


@media screen and (max-width: 991px) {

  .event {
    padding-top: 15px;
  }

  .event__wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .event__wrapper--inner {
    margin-left: 0;
    margin-right: 0;
  }

  .event__textblock {
    font-size: 18px;

  }

  .event__text .event__text--bold {
    font-size: 22px;
  }
  .event__description {
    display: flex;
    justify-content: center;

  }
  .event__image {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 15px;
  }
  .event__image img {
    width: 90%;
    margin: 0 auto;
  }


  .event__order-title {
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 30px;
  }

  .event__carousel {
    padding-top: 20px;
  }

  .event__carousel-item img {
    height: 300px;


  }

  .event__order-cta-text {
    font-size: 23px;
  }

  .event__order-cta-link {
    font-size: 23px;
  }
}



@media screen and (min-width: 768px) and (max-width: 1024px) {
  .event__main-text {
    font-size: 17px;
  }

  .event__quote-text {
    max-width: 250px;
    font-size: 24px;
    line-height: 35px;
  }
}


  @media screen and (min-width: 320px) and (max-width: 768px) {
    .event__text--bold {
      font-size: 28px;
    }


    .event__main-text {
      font-size: 18px;
    }
    .event__quote-text {
      display: none;
    }

    .event-pictures {
      display:none;
    }
  }

    @media screen and (min-width: 320px) and (max-width: 424px) {

      .event__text--bold {
        font-size: 24px;
      }

    }


