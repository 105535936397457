
@import "mixins";
@import "fonts";
@import "vars";




// styles for first cover
.main-image__wrapper {
  height: 100vh;
  background-color: green;
  position: static;
  top: 0px;
  left: 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}


.main-image__text {
  position: absolute;
  color:white;
  font-weight: 600;
  padding-top: 40vh;
  line-height: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.main-image__text-big {
  font-size: 72px;
  // padding-bottom: 30px;
  padding-top: 40px;
  font-weight: 600;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.59);

}

.main-image__text-small {
  width: 60%;
  font-size: 24px;
  line-height: 1.5;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.39);

}


// -------bouncing arrow-------------
.arrow-down__wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 90vh;
  z-index: 980;
}

#scroll_down {
  z-index: 980;

}
.arrow-down__icon {

  width: 50px;
  height: 50px;
  animation: bounce 3s ease infinite;

}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }

  55% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }


  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }

  55% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }


  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@media screen and (min-width: 993px) and (max-width: 1199px) {

  .main-image__text {
    padding-top: 43vh;
  }

  .main-image__text-big {
    font-size: 62px;
  }

  .main-image__text-small {
    font-size: 22px;
    line-height: 1.3;
  }

  .arrow-down__icon {
    width: 45px;
    height: 45px;
  }

}

@media screen and (min-width: 768px) and (max-width: 992px) {

  .header-small__logo-image {
    max-height: 225px;
  }

  .main-image__text-big {
    font-size: 45px;
  }

  .main-image__text-small {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
  }

  .arrow-down__icon {
    width: 35px;
    height: 35px;
  }
}

@media screen and (min-width: 577px) and (max-width: 767px) {
  .header-small__logo-image {
    max-height: 215px;
  }

  .main-image__text-big {
    font-size: 35px;
    line-height: 1.2;
  }

  .main-image__text-small {
    width: 75%;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400;
  }

  .arrow-down__icon {
    width: 35px;
    height: 35px;
  }
}

@media screen and (min-width: 376px) and (max-width: 576px) {
  .header-small__logo-image {
    max-height: 200px;
  }

  .main-image__text-big {
    font-size: 27px;
    line-height: 1.2;
  }

  .main-image__text-small {
    width: 75%;
    font-size: 15px;
    line-height: 1.2;
    font-weight: 400;
  }

  .arrow-down__icon {
    width: 35px;
    height: 35px;
  }

}

@media screen and (min-width: 351px) and (max-width: 375px) {
  .header-small__logo-image {
    //margin-top: 75px;
    //max-height: 225px;
  }

  .main-image__text-big {
    // padding-top: 30px;
    padding-top: 70px;
    font-size: 27px;
    line-height: 1.2;
  }

  .main-image__text-small {
    padding-top: 25px;
    width: 90%;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
  }

  .arrow-down__icon {
    width: 30px;
    height: 30px;
  }

}

@media screen and (max-width: 350px) {
  .header-small__logo-image {
    margin-top: 0;
    max-height: 225px;
  }

  .main-image__text-big {
    padding-top: 15px;
    font-size: 27px;
    line-height: 1.2;
    padding-bottom: 0px;
  }

  .main-image__text-small {
    padding-top: 0px;
    width: 90%;
    font-size: 15px;
    line-height: 1.2;
    font-weight: 400;
  }

  .arrow-down__icon {
    width: 30px;
    height: 30px;
  }

}
