@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";
html { overflow-x: hidden;}
body {
  opacity: 1 ;
  font-family: $default-font;
}

ul {
  padding-left: 0;
}

p {
  margin:0;
}


// styles for booking forms



 #block-search-main {
   background-color: #0071c2;
   width: 100%;
   box-sizing: border-box;
   max-width: 1140px;
   margin: 20px auto;
   position: relative;
   z-index: 9;
   font-weight: 300;
 }
.tl-container-main {
  padding: 0 21px;
}


 #tl-booking-form {
   margin:  50px auto 100px;
   max-width: 1140px;
 }


//костыль для лайтбокса экко

// ekko-lightbox.js (который в bower_components/dist)
// config
// leftArrow: '<img src="../img/al1.png" class="lightbox-arrow lightbox-arrow__left">',
// rightArrow: '<img src="../img/ar1.png" class="lightbox-arrow lightbox-arrow__right">',
//"./img/al1.png"

.img-fluid {
  max-height: 100%;
}

.lightbox-arrow {
  position: absolute;
  top: 45%;
  opacity: 0.7;
}

.lightbox-arrow__left {
  left: 3px;
}

.lightbox-arrow__right {
  right: 3px;
}

//
////owl --> weddings.php
//.owl-carousel {
//  position: relative;
//}
//.owl-carousel .nav-btn{
//  height: 47px;
//  position: absolute;
//  width: 26px;
//  cursor: pointer;
//  top: 50% !important;  // указать для конкретного макета навигацию смещение
//}
//
//.owl-carousel .owl-prev.disabled,
//.owl-carousel .owl-next.disabled{
//  pointer-events: none;
//  opacity: 0.2;
//}
//
//.owl-carousel .prev-slide{
//  background: url(../img/nav-icon.png) no-repeat scroll 0 0;
//  left: -33px;
//}
//.owl-carousel .next-slide{
//  background: url(../img/nav-icon.png) no-repeat scroll -24px 0px;
//  right: -33px;
//}
//.owl-carousel .prev-slide:hover{
//  background-position: 0px -53px;
//}
//.owl-carousel .next-slide:hover{
//  background-position: -24px -53px;
//}
//
//
//@media screen and (min-width: 1024px) and (max-width: 1200px){
//  .owl-carousel .prev-slide{
//    left:0px;
//  }
//  .owl-carousel .next-slide{
//    right:0px;
//  }
//
//}


