
// covers for first screen images

.main-image-bg {
  background: linear-gradient(160deg, rgba(255, 102, 102, 0.35), rgba(108, 104, 116, 0.35)),url(../img/_5-1.png);
  background-position: top center;
  background-size: cover;
}
.duplex-bg {
  background: linear-gradient(180deg, rgba(13, 12, 13, 0.4), rgba(0, 0, 0, 0.4)),url(../img/dd-bg.jpg);
  background-position: bottom center;
  background-size: cover;
}

.price-bg {
  background: linear-gradient(180deg, rgba(13, 12, 13, 0.4), rgba(0, 0, 0, 0.4)),url(../img/dd-bg.jpg);
  background-position: bottom center;
  background-size: cover;
}


.summer_houses-bg {
  background: linear-gradient(180deg, rgba(13, 12, 13, 0.4), rgba(0, 0, 0, 0.3)),url(../img/11-1.jpg);
  background-size: cover;
}

.event-bg {
  background: linear-gradient(45deg, rgba(13, 12, 13, 0.1), rgba(0, 0, 0, 0.1)),url(../img/merop.jpeg);
  background-size: cover;
  background-position: bottom bottom;

}

.zoo-bg {
  background: linear-gradient(180deg, rgba(13, 12, 13, 0.3), rgba(0, 0, 0, 0.2)),url(../img/racoon1.jpeg);
  background-size: cover;
  background-position: left;

}

.bathhouse-bg {
  background: linear-gradient(180deg, rgba(255, 186, 0, 0.7), rgba(0, 0, 0, 0.4)),url(../img/bathhouse.jpeg);
  background-size: cover;
}

.contacts-bg {
  background: linear-gradient(45deg, rgba(13, 13, 12, 0.5), rgba(0, 0, 0, 0.5)),url(../img/_fishing.jpg);
  background-size: cover;
}

