@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";

// ----------header styles------------


.header__container {
  background-color: transparent;
  z-index: 999;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
}

.header-small {
  display: none;
}


.header-big__logo {
  padding-left: 22px;
  padding-top: 8px;
  img {
    width: 400px;
    height: 82px;
  }
}

.header-big__filler {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-big__filler-text {
  color: white;
  font-size: 19px;
  font-weight: 100;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.4);
}


.header-big__contacts {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  padding-top: 10px;

}

.header-big__order {
  display: flex;
  align-items: center;
  //justify-content: flex-start;

}

.header-big__order-button {
  font-size: 18px;
  font-weight: 300;
  color: white;
  border: 2px solid white;
  border-radius: 30px;
  background: transparent;
  //text-transform: uppercase;
  padding: 10px 30px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);


  &:hover {
    text-decoration: none;
    color: white;
  }
}

.header-big__contacts-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  line-height: 1;
  padding-top: 10px;
  a, a:hover {
    color: white;
    font-size: 18px;
    font-weight: 300;
    text-decoration: none;
    padding-left: 10px;
  }
  i {
    color: white;
    font-size: 20px;
  }
}

.header-big__tel, .header-big__email {
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
}


.header-big__location {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header-big__location a {
  font-size: 16px;
  font-weight: 100;
  padding-top: 8px;
  padding-left: 0;
  border-bottom: 1px solid white;
}

.header-big__location a:hover {
  font-size: 16px;
  font-weight: 100;
  padding-top: 8px;
  text-decoration: none;
  padding-left: 0;
  border-bottom: 1px solid white;
}
.header-big-bottom {
  //border-top: 1px solid antiquewhite;
  margin-top: 5px;
  padding-top: 10px;
}

.header-big-bottom__nav-items {
  list-style: none;
  margin: 0;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.header-big-bottom__nav-link {
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: 300;
  color: white;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.39);
  //text-decoration: underline;
  border-bottom: 1px solid white;
  &:hover {
    text-decoration: none;
    color: white;
    text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.39);

  }
}

  //-- old below


@media (max-width: 1200px) {

  .header-big, .header-big-bottom {
    display: none;
  }
  .header-small {
    display: flex;
    z-index: 999;
    position: absolute;
    top:0;
    width: 100%;
    height: 50vh;
  }

  // .header-small>div {
  //   max-height: 200px;
  // }

  .header-small__logo {
    display: flex;
    justify-content: flex-start;
    padding-top: 25px;
    height: 100px;
    //z-index: 90;
    //max-height: 50px;
    a {
      max-height:50px;
    }
  }

  .header-small__logo-image {
    width: 200px;
    height: 40px;
    //max-width: 400px;
    //max-height: 80px;
  }
}


/* mobile navigation button */


.menu-icon {
  /*   background: red; */
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  z-index: 990;
  position: absolute;
  top:25px;
  right:30px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .menu-icon {
    display: flex;
    position: fixed;
    border-radius: 100px;
    width: 48px;
    height: 48px;
    background-color: rgba(17, 17, 17, 0.8);

  }

}

.menu-icon-line {
  position: relative;
  width: 25px;
  height: 3px;
  background-color: #fff;
}

.menu-icon-line::before {
  position: absolute;
  left: 0;
  top: -9px;
  content: '';
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: transform 0.2s ease-in, top 0.2s linear 0.2s;

}

.menu-icon-line::after {
  position: absolute;
  left: 0;
  top: 9px;
  content: '';
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: transform 0.2s ease-in, top 0.2s linear 0.2s;

}

.menu-icon.menu-icon-active .menu-icon-line{
  background-color: transparent;
}

.menu-icon.menu-icon-active .menu-icon-line::before {
  transform: rotate(45deg);
  top: 0;
  transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
}

.menu-icon.menu-icon-active .menu-icon-line::after {
  transform: rotate(-45deg);
  top: 0;
  transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
}

/* mobile navigation */
.header-small__navigation {
  position: relative;
  z-index: 1000;
  width: 100%;
  // overflow: hidden;
  height:100vh;
}

.mobile-nav {
  z-index: 900;
  display: block;
  position: fixed;
  right: -368px;
  top:0px;
  height: 100%;
  width: 345px;
  background-color: rgba(0, 0, 0, .4);
  cursor: move;
  overflow: hidden;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  //background-color: #111;;
  padding: 50px;
  padding-left: 35px;
  transition: transform 0.5s ease-in;
}


@-moz-document url-prefix(){
  .mobile-nav {
    background-color: rgba(0, 0, 0, .9);

  }
}

.mobile-nav--active {
  transform: translateX(-100%);
}

.body-freeze {
  overflow: hidden;
  height: 100vh;
}

.mobile-nav__logo {
  display: flex;
  justify-content: center;
}

.mobile-nav__logo-image {
  max-width: 60px;
}


.mobile-nav__list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  padding-top: 30px;
}

.mobile-nav__item {
  margin-bottom: 15px;


}

.mobile-nav__link {
  text-decoration: none;
  color: white;
  font-size: 24px;
  &:hover {
    text-decoration: none;
    color: white;
    font-size: 24px;
  }
}


.mobile-nav__text {
  width: 90%;
  font-size: 13px;
  padding-top: 13px;
  // padding-top: 30vh;
  color:white;
}

.mobile-nav__contacts {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
  img {
    width: 30px;
    height: 30px;
  }



}

.header__social-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 15px 15px 0;

  a {
    display: flex;
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    //border: 2px solid #818181;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    text-decoration: none;
    margin-right: 15px;


    &:last-of-type {
      //margin-left: 15px;
      margin-right: 0;
    }

  }

  i {
    color: #fff;
    //color: #818181;
    font-size: 19px;
  }

}


.header__social--fb:hover {

  border: 2px solid $fb;

  i {
    color: $fb;
  }
}

.header__social--vk:hover {

  border: 2px solid $vk;

  i {
    color: $vk;
  }
}

.header__social--wa:hover {

  border: 2px solid $wa;

  i {
    color: $wa;
  }
}

.header__social--yt:hover {

  border: 2px solid $yt;

  i {
    color: $yt;
  }
}

.header__social--ig:hover {

  border: 2px solid deeppink;

  i {
    color: deeppink;
  }
}


//
// @media screen and (min-width: 768px) and (max-width: 992px) {}
// @media screen and (min-width: 577px) and (max-width: 767px) {}
//
// @media screen and (min-width: 376px) and (max-width: 576px) {}
//
// @media screen and (max-width: 375px) {}
