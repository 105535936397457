/*
* Third Party
*/
@import url(../../bower_components/normalize.css/normalize.css);
@import url(../../bower_components/bootstrap/dist/css/bootstrap.css);
@import url(../../bower_components/ekko-lightbox/dist/ekko-lightbox.css);
@import url(../../bower_components/owl-carousel2/dist/assets/owl.carousel.css);
@import url(../../bower_components/owl-carousel2/dist/assets/owl.theme.default.css);
/*
* Custom
*/
@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.about {
  padding-top: 60px;
  padding-bottom: 10px; }

.about_wrapper {
  display: flex;
  justify-content: space-evenly; }

.about__title {
  color: black;
  font-weight: 600;
  font-size: 48px;
  line-height: 1.23;
  margin: 0;
  padding-bottom: 20px; }

.about__contacts {
  color: black;
  font-size: 16px;
  line-height: 1.55;
  font-weight: 700;
  padding-bottom: 15px; }

.about__contacts-addr {
  opacity: .5; }

.about__contacts-tel {
  color: black;
  opacity: .5; }
  .about__contacts-tel:hover {
    color: black;
    opacity: .5;
    text-decoration: none; }

.about__text {
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 300; }

.about__link, .about__link:hover {
  text-decoration: none; }

.about__wrapper-bottom {
  outline: 1px solid red;
  display: flex;
  justify-content: center; }

.about__image {
  max-width: 100%; }

.showplace {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse; }

.showplace__title {
  display: flex;
  justify-content: center;
  padding-top: 20px; }
  .showplace__title h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 1.23;
    padding-bottom: 5px;
    margin: 0; }

.showplace__description {
  display: flex;
  justify-content: center;
  color: black;
  font-size: 16px;
  line-height: 1.55;
  font-weight: 500;
  padding-bottom: 15px;
  opacity: 0.5; }

.showplace__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased; }

.showplace__item-distance {
  padding-left: 20px; }

@media screen and (min-width: 768px) and (max-width: 992px) {
  .about__text {
    font-size: 15px; }
  .showplace__item-name, .showplace__item-distance {
    font-size: 15px; } }

@media screen and (max-width: 375px) {
  .about {
    padding-top: 20px; }
  .about__title {
    font-size: 35px; }
  .showplace__title h3 {
    font-size: 25px; }
  .showplace__item {
    justify-content: space-between;
    font-size: 14px; }
  .showplace__item-distance {
    padding-left: 0px; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.accommodation {
  padding-top: 60px;
  -webkit-font-smoothing: subpixel-antialiased; }

.accommodation__title {
  display: flex;
  justify-content: center; }
  .accommodation__title h2 {
    text-align: center;
    color: black;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.23;
    margin: 0;
    padding-bottom: 10px; }

.accommodation__wrapper {
  display: flex;
  justify-content: center; }

.accommodation__lead {
  opacity: .5;
  font-size: 20px;
  line-height: 1.55;
  padding-bottom: 10px; }

.accommodation__types-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 40px; }

.accommodation__types-item--first {
  padding-bottom: 20px; }

.accommodation__info {
  display: flex;
  flex-direction: column; }

.accommodation__info-title {
  color: black;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.23;
  margin: 0;
  text-align: center;
  padding-bottom: 15px; }

.accommodation__capacity {
  opacity: .5;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.55;
  padding-bottom: 15px; }

.accommodation__description {
  font-size: 20px;
  line-height: 1.55;
  font-weight: 300;
  padding-bottom: 20px; }

.accommodation__button {
  color: #ffffff;
  background-color: #0071c2;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 500;
  background-image: none;
  cursor: pointer;
  letter-spacing: 0.03em;
  width: 135px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.5); }
  .accommodation__button:hover {
    color: white;
    text-decoration: none;
    background-color: #1d499e; }

.accommodation__carousel-item img {
  object-fit: cover;
  height: 400px;
  display: block; }

@media screen and (min-width: 768px) and (max-width: 992px) {
  .accommodation {
    padding-top: 30px; }
  .accommodation__carousel {
    padding-bottom: 20px; }
  .accommodation__description {
    font-size: 18px; } }

@media screen and (min-width: 376px) and (max-width: 767px) {
  .accommodation {
    padding-top: 30px; }
  .accommodation__title h2 {
    font-size: 28px;
    text-align: center; }
  .accommodation__info {
    align-items: center;
    padding-bottom: 35px; }
  .accommodation__carousel {
    padding-bottom: 20px; } }

@media screen and (max-width: 375px) {
  .accommodation {
    padding-top: 20px; }
  .accommodation__title h2 {
    font-size: 28px;
    text-align: center; }
  .accommodation__lead {
    text-align: center;
    font-size: 18px; }
  .accommodation__carousel {
    padding-left: 0;
    padding-right: 0; }
  .accommodation__types-item {
    padding-top: 0; }
  .accommodation__carousel-item img {
    object-fit: cover;
    height: 300px;
    display: block; }
  .accommodation__types-item--first .accommodation__info {
    padding-top: 10px; }
  .accommodation__description {
    font-size: 18px; }
  .accommodation__info {
    align-items: center;
    padding-bottom: 35px; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.fishing {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9), rgba(199, 226, 234, 0.95));
  padding-top: 40px;
  padding-bottom: 30px;
  -webkit-font-smoothing: subpixel-antialiased; }

.fishing__title {
  display: flex;
  justify-content: center; }
  .fishing__title h2 {
    text-align: center;
    color: black;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.23;
    margin: 0;
    padding-bottom: 20px; }

.fishing__wrapper {
  display: flex;
  justify-content: center; }

.fishing__lead {
  text-align: center;
  font-weight: 300;
  font-size: 25px;
  line-height: 1.37; }

.fishing__image {
  width: 90%;
  max-width: 1053px; }

.fishing__image-wrapper {
  display: flex;
  justify-content: center; }

.fishing__list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 10px; }

.last-fish-list {
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap; }

.fishing__list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  padding-bottom: 10px; }

.fishing__list-item-last {
  width: 50%; }

.fishing__list-item-pic {
  width: 80px; }

.som {
  width: 60px; }

.fishing__list-item-fishname {
  padding-left: 15px;
  line-height: 1; }

.som-text {
  padding-left: 35px; }

.fishing__text-wrapper {
  display: flex;
  justify-content: center; }

.fishing__text {
  font-size: 20px;
  line-height: 1.55;
  font-weight: 300;
  text-align: center; }

.fishing__endtext {
  padding-top: 10px;
  font-size: 25px;
  line-height: 1.55;
  font-weight: 300;
  text-align: center; }

@media screen and (min-width: 768px) and (max-width: 992px) {
  .fishing__text {
    font-size: 15px;
    padding-left: 0px;
    padding-right: 0px; }
  .fishing__lead {
    text-align: left;
    font-size: 20px; }
  .fishing__list-item-pic {
    width: 60px; }
  .fishing__list-item-fishname {
    font-size: 14px;
    padding-left: 10px; }
  .fishing__endtext {
    font-size: 20px; } }

@media screen and (min-width: 376px) and (max-width: 991px) {
  .fishing__lead {
    text-align: center;
    font-size: 20px; }
  .fishing__endtext {
    font-size: 20px; } }

@media screen and (max-width: 375px) {
  .fishing {
    padding-top: 20px; }
  .fishing__title h2 {
    font-size: 28px;
    text-align: center; }
  .fishing__lead {
    text-align: left;
    font-size: 18px; }
  .fishing__image {
    width: 100%;
    min-width: 320px; }
  .fishing__text-wrapper {
    flex-direction: column; }
  .fishing__text {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    text-align: left; }
  .fishing__endtext {
    font-size: 18px; }
  .fishing__list-item-pic {
    width: 60px; }
  .fishing__list-item-fishname {
    font-size: 14px;
    padding-left: 10px; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.fishing-price {
  padding-bottom: 40px;
  -webkit-font-smoothing: subpixel; }

.price__title {
  display: flex;
  justify-content: center;
  padding-top: 40px; }
  .price__title h2 {
    color: black;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.23;
    margin: 0;
    text-align: center;
    padding-bottom: 15px; }

.title--small {
  font-size: 25px;
  padding-top: 0px;
  padding-bottom: 20px; }
  .title--small h3 {
    text-align: center; }

.price__wrapper {
  display: flex;
  justify-content: center;
  padding-top: 0px; }

.price__text {
  font-size: 20px;
  line-height: 1.55;
  font-weight: 300;
  padding-bottom: 20px;
  text-align: center; }

.price__table th {
  text-align: center; }

.price__table td {
  text-align: center; }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .price__text {
    font-size: 18px; } }

@media screen and (max-width: 575px) {
  .price_title {
    font-size: 26px; }
  .title--small h3 {
    font-size: 22px; }
  .price__text {
    font-size: 16px; }
  .price__table th {
    font-size: 14px; }
  .price__table td {
    font-size: 14px; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.house {
  padding-top: 50px;
  padding-bottom: 50px; }

.house__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.house__title h2 {
  margin: 0;
  color: black;
  font-weight: 600;
  font-size: 35px;
  line-height: 1.23;
  text-align: center;
  padding-bottom: 15px; }

.house__brief-description, .house__description {
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 300; }

.house__description {
  display: flex;
  flex-direction: column;
  padding-top: 10px; }

.house__furniture {
  display: flex;
  flex-direction: column; }

.house__furniture-wrapper {
  display: flex;
  align-items: center; }

.house__furniture-image {
  width: 45px;
  padding-left: 10px; }

.house__list {
  margin: 0;
  padding-left: 20px; }

.house__header-left {
  padding-top: 10px;
  padding-bottom: 10px; }

.house__header-right {
  padding-top: 30px;
  padding-bottom: 10px; }

.house__carousel-item img {
  object-fit: cover;
  height: 400px;
  display: block; }

.house__link {
  text-decoration: none; }
  .house__link:hover {
    text-decoration: none; }

.house__order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px; }

.house__order-title {
  color: black;
  font-weight: 600;
  font-size: 35px;
  line-height: 1.23;
  text-align: center;
  padding-bottom: 15px; }

.house__order-text {
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 22px;
  line-height: 1.55;
  font-weight: 300;
  text-align: center; }

@media screen and (max-width: 1200px) {
  .house__furniture-image {
    display: none; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .house__title h2 {
    font-size: 26px; }
  .house__description {
    font-size: 16px; }
  .house__order-title {
    font-size: 25px; }
  .house__order-text {
    font-size: 18px; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .house__carousel {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0; }
  .house__order-text {
    font-size: 17px; } }

@media screen and (max-width: 575px) {
  .house {
    padding-top: 20px; }
  .house__header-right {
    padding-top: 20px; }
  .house__title h2 {
    font-size: 28px; }
  .house__brief-description {
    font-size: 15px; }
  .house__description {
    font-size: 16px; }
  .house__carousel {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0; }
  .house__carousel-item img {
    height: 300px; }
  .house__order-title {
    font-size: 25px; }
  .house__order-text {
    font-size: 18px; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.event {
  padding-top: 30px; }

.event__text {
  font-size: 18px;
  font-weight: 300;
  color: black;
  text-align: center;
  padding-top: 15px; }
  .event__text--bold {
    font-size: 36px;
    font-weight: 700; }

.event__wrapper {
  display: flex;
  justify-content: center;
  padding: 30px 0; }

.event__wrapper--inner {
  margin-left: 10px;
  margin-right: 10px; }

.event__quote-text {
  float: right;
  max-width: 400px;
  margin-left: 30px;
  font-size: 36px;
  font-weight: 300;
  font-style: italic;
  line-height: 40px; }
  .event__quote-text-little {
    padding-top: 10px;
    font-size: 30px;
    text-align: right; }
  .event__quote-text:before {
    content: '';
    display: block;
    height: 3px;
    background: linear-gradient(135deg, deeppink 20%, rebeccapurple 70%);
    margin-top: 15px;
    margin-bottom: 16px;
    width: 40%; }

.event__main-text {
  margin: 0;
  float: none;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.55; }

.event__text-little {
  font-size: 19px; }

.event__textblock {
  margin: 0;
  float: none;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.55;
  padding-top: 0; }

.event__textblock-divider {
  padding-top: 10px; }

.event__description-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.event__description {
  display: flex;
  flex-direction: column; }

.event__description-wrapper-inner {
  padding-top: 10px; }

.event__image {
  padding-top: 5px; }
  .event__image img {
    width: 100%; }

.event__order {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 30px; }

.event__order-title {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px; }
  .event__order-title h2 {
    margin: 0;
    color: black;
    font-weight: 600;
    font-size: 35px;
    line-height: 1.23;
    text-align: center;
    padding-bottom: 15px; }

.event__order-cta-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px; }

.event__order-cta-text {
  margin: 0;
  color: black;
  font-weight: 300;
  font-size: 30px;
  line-height: 1.23;
  text-align: center;
  padding-bottom: 15px; }

.event__order-cta-link {
  display: flex;
  justify-content: center; }
  .event__order-cta-link a, .event__order-cta-link a:hover {
    color: black;
    font-weight: 300;
    font-size: 25px;
    text-decoration: none; }

.event__carousel-itemv img {
  object-fit: contain;
  height: 400px;
  filter: brightness(100%); }

.event-pictures {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 15px; }

.event-pictures__wrapper-outer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.event-pictures__outer-item {
  display: flex;
  width: 47%;
  min-width: 280px;
  padding-bottom: 15px; }
  .event-pictures__outer-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.event-pictures__wrapper-inner {
  width: 50%;
  min-width: 280px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.event-pictures__inner-item {
  width: 100%;
  height: 48%; }
  .event-pictures__inner-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .event__quote-text {
    font-size: 20px; }
  .event__quote-text-little {
    font-size: 27px;
    padding-top: 5px; } }

@media screen and (max-width: 991px) {
  .event {
    padding-top: 15px; }
  .event__wrapper {
    padding-top: 15px;
    padding-bottom: 15px; }
  .event__wrapper--inner {
    margin-left: 0;
    margin-right: 0; }
  .event__textblock {
    font-size: 18px; }
  .event__text .event__text--bold {
    font-size: 22px; }
  .event__description {
    display: flex;
    justify-content: center; }
  .event__image {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 15px; }
  .event__image img {
    width: 90%;
    margin: 0 auto; }
  .event__order-title {
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 30px; }
  .event__carousel {
    padding-top: 20px; }
  .event__carousel-item img {
    height: 300px; }
  .event__order-cta-text {
    font-size: 23px; }
  .event__order-cta-link {
    font-size: 23px; } }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .event__main-text {
    font-size: 17px; }
  .event__quote-text {
    max-width: 250px;
    font-size: 24px;
    line-height: 35px; } }

@media screen and (min-width: 320px) and (max-width: 768px) {
  .event__text--bold {
    font-size: 28px; }
  .event__main-text {
    font-size: 18px; }
  .event__quote-text {
    display: none; }
  .event-pictures {
    display: none; } }

@media screen and (min-width: 320px) and (max-width: 424px) {
  .event__text--bold {
    font-size: 24px; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.zoo {
  padding-top: 30px;
  padding-bottom: 15px; }

.zoo__textblock {
  -webkit-font-smoothing: antialiased;
  font-size: 25px;
  font-weight: 300;
  text-align: center; }

.zoo__title {
  -webkit-font-smoothing: antialiased;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  padding-top: 20px; }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .zoo__textblock {
    font-size: 18px; } }

@media screen and (max-width: 575px) {
  .zoo__textblock {
    font-size: 18px; }
  .zoo__title {
    font-size: 32px; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.animal {
  padding-top: 25px;
  padding-bottom: 25px; }

.animal__wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.animal_180 {
  flex-direction: row-reverse; }

.animal__description {
  -webkit-font-smoothing: antialiased; }

.animal__title {
  margin: 0;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 20px; }

.animal__text {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.4; }

.animal__carousel-item img {
  object-fit: cover;
  height: 400px;
  display: block; }

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .animal__text {
    font-size: 20px; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .animal__text {
    font-size: 18px; } }

@media screen and (max-width: 575px) {
  .animal {
    padding-top: 15px;
    padding-bottom: 15px; }
  .animal__text {
    font-size: 18px;
    padding-bottom: 20px; }
  .animal__carousel, .animal__carousel-wrapper {
    padding-left: 0;
    padding-right: 0; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.contacts {
  padding-top: 30px;
  padding-bottom: 30px; }

.contacts__wrapper {
  display: flex;
  justify-content: center; }

.contacts__title {
  color: black;
  font-weight: 600;
  font-size: 35px;
  line-height: 1.23;
  text-align: center;
  padding-bottom: 15px; }

.contacts__title--pt {
  padding-top: 20px; }

.contacts__mail {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.contacts__telephone {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.fa-phone, .fa-mail {
  font-size: 20px; }

.contacts__telephone-link, .contacts__telephone-link:hover {
  text-decoration: none;
  font-size: 20px;
  font-weight: 300;
  color: black;
  padding-left: 15px; }

.contacts__mail-link, .contacts__mail-link:hover {
  text-decoration: none;
  font-size: 20px;
  font-weight: 300;
  color: black;
  padding-left: 15px; }

.contacts__location {
  display: flex;
  justify-content: center;
  padding-bottom: 20px; }
  .contacts__location a {
    align-items: center; }

.contacts__location-link, .contacts__location-link:hover {
  font-size: 20px;
  font-weight: 300; }

@media screen and (max-width: 575px) {
  .contacts__title {
    font-size: 26px; }
  .contacts__telephone {
    justify-content: center; }
  .contacts__mail {
    justify-content: center; }
  .contacts__location-link,
  .contacts__location-link:hover {
    text-align: center; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.bathhouse {
  padding-top: 30px;
  padding-bottom: 30px; }

.bathhouse__wrapper {
  display: flex;
  justify-content: center; }

.bathhouse__text {
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  line-height: 1.55;
  font-weight: 300; }

.bathhouse__link {
  text-decoration: none; }
  .bathhouse__link:hover {
    text-decoration: none; }

.bathhouse__carousel-item img {
  object-fit: cover;
  height: 400px;
  display: block; }

.bathhouse__cta {
  -webkit-font-smoothing: antialiased;
  padding-top: 30px;
  font-size: 22px;
  line-height: 1.55;
  font-weight: 300;
  text-align: center; }

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .bathhouse__cta {
    font-size: 20px;
    padding-left: 0;
    padding-right: 0; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .bathhouse__text {
    padding-bottom: 20px; }
  .bathhouse__cta {
    font-size: 20px;
    padding-left: 0;
    padding-right: 0; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .bathhouse__text {
    font-size: 20px;
    padding-bottom: 20px; }
  .bathhouse__cta {
    font-size: 18px;
    padding-left: 0;
    padding-right: 0; }
  .bathhouse__carousel-item img {
    height: 300px; } }

@media screen and (max-width: 575px) {
  .bathhouse__text {
    font-size: 17px;
    padding-bottom: 20px; }
  .bathhouse__cta {
    font-size: 17px;
    padding-left: 0;
    padding-right: 0; }
  .bathhouse__carousel-item img {
    height: 300px; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

html {
  overflow-x: hidden; }

body {
  opacity: 1;
  font-family: "roboto-df", sans-serif; }

ul {
  padding-left: 0; }

p {
  margin: 0; }

#block-search-main {
  background-color: #0071c2;
  width: 100%;
  box-sizing: border-box;
  max-width: 1140px;
  margin: 20px auto;
  position: relative;
  z-index: 9;
  font-weight: 300; }

.tl-container-main {
  padding: 0 21px; }

#tl-booking-form {
  margin: 50px auto 100px;
  max-width: 1140px; }

.img-fluid {
  max-height: 100%; }

.lightbox-arrow {
  position: absolute;
  top: 45%;
  opacity: 0.7; }

.lightbox-arrow__left {
  left: 3px; }

.lightbox-arrow__right {
  right: 3px; }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.header__container {
  background-color: transparent;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.header-small {
  display: none; }

.header-big__logo {
  padding-left: 22px;
  padding-top: 8px; }
  .header-big__logo img {
    width: 400px;
    height: 82px; }

.header-big__filler {
  display: flex;
  justify-content: center;
  align-items: center; }

.header-big__filler-text {
  color: white;
  font-size: 19px;
  font-weight: 100;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.4); }

.header-big__contacts {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-top: 10px; }

.header-big__order {
  display: flex;
  align-items: center; }

.header-big__order-button {
  font-size: 18px;
  font-weight: 300;
  color: white;
  border: 2px solid white;
  border-radius: 30px;
  background: transparent;
  padding: 10px 30px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2); }
  .header-big__order-button:hover {
    text-decoration: none;
    color: white; }

.header-big__contacts-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  line-height: 1;
  padding-top: 10px; }
  .header-big__contacts-wrapper a, .header-big__contacts-wrapper a:hover {
    color: white;
    font-size: 18px;
    font-weight: 300;
    text-decoration: none;
    padding-left: 10px; }
  .header-big__contacts-wrapper i {
    color: white;
    font-size: 20px; }

.header-big__tel, .header-big__email {
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px; }

.header-big__location {
  display: flex;
  justify-content: center;
  width: 100%; }

.header-big__location a {
  font-size: 16px;
  font-weight: 100;
  padding-top: 8px;
  padding-left: 0;
  border-bottom: 1px solid white; }

.header-big__location a:hover {
  font-size: 16px;
  font-weight: 100;
  padding-top: 8px;
  text-decoration: none;
  padding-left: 0;
  border-bottom: 1px solid white; }

.header-big-bottom {
  margin-top: 5px;
  padding-top: 10px; }

.header-big-bottom__nav-items {
  list-style: none;
  margin: 0;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly; }

.header-big-bottom__nav-link {
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: 300;
  color: white;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.39);
  border-bottom: 1px solid white; }
  .header-big-bottom__nav-link:hover {
    text-decoration: none;
    color: white;
    text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.39); }

@media (max-width: 1200px) {
  .header-big, .header-big-bottom {
    display: none; }
  .header-small {
    display: flex;
    z-index: 999;
    position: absolute;
    top: 0;
    width: 100%;
    height: 50vh; }
  .header-small__logo {
    display: flex;
    justify-content: flex-start;
    padding-top: 25px;
    height: 100px; }
    .header-small__logo a {
      max-height: 50px; }
  .header-small__logo-image {
    width: 200px;
    height: 40px; } }

/* mobile navigation button */
.menu-icon {
  /*   background: red; */
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  z-index: 990;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer; }

@media (max-width: 1200px) {
  .menu-icon {
    display: flex;
    position: fixed;
    border-radius: 100px;
    width: 48px;
    height: 48px;
    background-color: rgba(17, 17, 17, 0.8); } }

.menu-icon-line {
  position: relative;
  width: 25px;
  height: 3px;
  background-color: #fff; }

.menu-icon-line::before {
  position: absolute;
  left: 0;
  top: -9px;
  content: '';
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: transform 0.2s ease-in, top 0.2s linear 0.2s; }

.menu-icon-line::after {
  position: absolute;
  left: 0;
  top: 9px;
  content: '';
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: transform 0.2s ease-in, top 0.2s linear 0.2s; }

.menu-icon.menu-icon-active .menu-icon-line {
  background-color: transparent; }

.menu-icon.menu-icon-active .menu-icon-line::before {
  transform: rotate(45deg);
  top: 0;
  transition: top 0.2s linear, transform 0.2s ease-in 0.2s; }

.menu-icon.menu-icon-active .menu-icon-line::after {
  transform: rotate(-45deg);
  top: 0;
  transition: top 0.2s linear, transform 0.2s ease-in 0.2s; }

/* mobile navigation */
.header-small__navigation {
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 100vh; }

.mobile-nav {
  z-index: 900;
  display: block;
  position: fixed;
  right: -368px;
  top: 0px;
  height: 100%;
  width: 345px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: move;
  overflow: hidden;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 50px;
  padding-left: 35px;
  transition: transform 0.5s ease-in; }

@-moz-document url-prefix() {
  .mobile-nav {
    background-color: rgba(0, 0, 0, 0.9); } }

.mobile-nav--active {
  transform: translateX(-100%); }

.body-freeze {
  overflow: hidden;
  height: 100vh; }

.mobile-nav__logo {
  display: flex;
  justify-content: center; }

.mobile-nav__logo-image {
  max-width: 60px; }

.mobile-nav__list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  padding-top: 30px; }

.mobile-nav__item {
  margin-bottom: 15px; }

.mobile-nav__link {
  text-decoration: none;
  color: white;
  font-size: 24px; }
  .mobile-nav__link:hover {
    text-decoration: none;
    color: white;
    font-size: 24px; }

.mobile-nav__text {
  width: 90%;
  font-size: 13px;
  padding-top: 13px;
  color: white; }

.mobile-nav__contacts {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px; }
  .mobile-nav__contacts img {
    width: 30px;
    height: 30px; }

.header__social-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 15px 15px 0; }
  .header__social-wrapper a {
    display: flex;
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    text-decoration: none;
    margin-right: 15px; }
    .header__social-wrapper a:last-of-type {
      margin-right: 0; }
  .header__social-wrapper i {
    color: #fff;
    font-size: 19px; }

.header__social--fb:hover {
  border: 2px solid #4267b2; }
  .header__social--fb:hover i {
    color: #4267b2; }

.header__social--vk:hover {
  border: 2px solid #2787F5; }
  .header__social--vk:hover i {
    color: #2787F5; }

.header__social--wa:hover {
  border: 2px solid #25d366; }
  .header__social--wa:hover i {
    color: #25d366; }

.header__social--yt:hover {
  border: 2px solid #c4302b; }
  .header__social--yt:hover i {
    color: #c4302b; }

.header__social--ig:hover {
  border: 2px solid deeppink; }
  .header__social--ig:hover i {
    color: deeppink; }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.main-image__wrapper {
  height: 100vh;
  background-color: green;
  position: static;
  top: 0px;
  left: 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1; }

.main-image__text {
  position: absolute;
  color: white;
  font-weight: 600;
  padding-top: 40vh;
  line-height: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }

.main-image__text-big {
  font-size: 72px;
  padding-top: 40px;
  font-weight: 600;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.59); }

.main-image__text-small {
  width: 60%;
  font-size: 24px;
  line-height: 1.5;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.39); }

.arrow-down__wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 90vh;
  z-index: 980; }

#scroll_down {
  z-index: 980; }

.arrow-down__icon {
  width: 50px;
  height: 50px;
  animation: bounce 3s ease infinite; }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px); }
  55% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px); }
  55% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@media screen and (min-width: 993px) and (max-width: 1199px) {
  .main-image__text {
    padding-top: 43vh; }
  .main-image__text-big {
    font-size: 62px; }
  .main-image__text-small {
    font-size: 22px;
    line-height: 1.3; }
  .arrow-down__icon {
    width: 45px;
    height: 45px; } }

@media screen and (min-width: 768px) and (max-width: 992px) {
  .header-small__logo-image {
    max-height: 225px; }
  .main-image__text-big {
    font-size: 45px; }
  .main-image__text-small {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400; }
  .arrow-down__icon {
    width: 35px;
    height: 35px; } }

@media screen and (min-width: 577px) and (max-width: 767px) {
  .header-small__logo-image {
    max-height: 215px; }
  .main-image__text-big {
    font-size: 35px;
    line-height: 1.2; }
  .main-image__text-small {
    width: 75%;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400; }
  .arrow-down__icon {
    width: 35px;
    height: 35px; } }

@media screen and (min-width: 376px) and (max-width: 576px) {
  .header-small__logo-image {
    max-height: 200px; }
  .main-image__text-big {
    font-size: 27px;
    line-height: 1.2; }
  .main-image__text-small {
    width: 75%;
    font-size: 15px;
    line-height: 1.2;
    font-weight: 400; }
  .arrow-down__icon {
    width: 35px;
    height: 35px; } }

@media screen and (min-width: 351px) and (max-width: 375px) {
  .main-image__text-big {
    padding-top: 70px;
    font-size: 27px;
    line-height: 1.2; }
  .main-image__text-small {
    padding-top: 25px;
    width: 90%;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400; }
  .arrow-down__icon {
    width: 30px;
    height: 30px; } }

@media screen and (max-width: 350px) {
  .header-small__logo-image {
    margin-top: 0;
    max-height: 225px; }
  .main-image__text-big {
    padding-top: 15px;
    font-size: 27px;
    line-height: 1.2;
    padding-bottom: 0px; }
  .main-image__text-small {
    padding-top: 0px;
    width: 90%;
    font-size: 15px;
    line-height: 1.2;
    font-weight: 400; }
  .arrow-down__icon {
    width: 30px;
    height: 30px; } }

.main-image-bg {
  background: linear-gradient(160deg, rgba(255, 102, 102, 0.35), rgba(108, 104, 116, 0.35)), url(../img/_5-1.png);
  background-position: top center;
  background-size: cover; }

.duplex-bg {
  background: linear-gradient(180deg, rgba(13, 12, 13, 0.4), rgba(0, 0, 0, 0.4)), url(../img/dd-bg.jpg);
  background-position: bottom center;
  background-size: cover; }

.price-bg {
  background: linear-gradient(180deg, rgba(13, 12, 13, 0.4), rgba(0, 0, 0, 0.4)), url(../img/dd-bg.jpg);
  background-position: bottom center;
  background-size: cover; }

.summer_houses-bg {
  background: linear-gradient(180deg, rgba(13, 12, 13, 0.4), rgba(0, 0, 0, 0.3)), url(../img/11-1.jpg);
  background-size: cover; }

.event-bg {
  background: linear-gradient(45deg, rgba(13, 12, 13, 0.1), rgba(0, 0, 0, 0.1)), url(../img/merop.jpeg);
  background-size: cover;
  background-position: bottom bottom; }

.zoo-bg {
  background: linear-gradient(180deg, rgba(13, 12, 13, 0.3), rgba(0, 0, 0, 0.2)), url(../img/racoon1.jpeg);
  background-size: cover;
  background-position: left; }

.bathhouse-bg {
  background: linear-gradient(180deg, rgba(255, 186, 0, 0.7), rgba(0, 0, 0, 0.4)), url(../img/bathhouse.jpeg);
  background-size: cover; }

.contacts-bg {
  background: linear-gradient(45deg, rgba(13, 13, 12, 0.5), rgba(0, 0, 0, 0.5)), url(../img/_fishing.jpg);
  background-size: cover; }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }

.footer {
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #0f305d;
  padding: 0 0 5px 0; }

.wave-top {
  position: relative; }

.wave-top::before,
.wave-top::after {
  border-bottom: 5px solid #0f305d; }

.wave-top::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  background-size: 20px 40px;
  background-image: radial-gradient(circle at 10px -15px, transparent 20px, #0f305d 21px); }

.wave-top::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15px;
  background-size: 40px 40px;
  background-image: radial-gradient(circle at 10px 26px, #0f305d 20px, transparent 21px); }

.footer__wrapper {
  padding-top: 20px; }

.footer__logo-wrapper {
  padding-bottom: 10px; }

.footer__logo-text {
  color: white;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.03em; }

.footer__logo-description {
  color: white;
  font-size: 16px;
  font-weight: 300;
  padding-top: 5px; }

.footer__location {
  color: white; }
  .footer__location-title {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 5px;
    letter-spacing: 0.03em; }
  .footer__location-text {
    font-size: 16px;
    font-weight: 300;
    padding-bottom: 5px; }
  .footer__location-link {
    display: flex;
    font-size: 16px;
    font-weight: 300;
    color: goldenrod;
    padding-bottom: 5px; }
  .footer__location-link:hover {
    font-size: 16px;
    color: goldenrod;
    text-decoration: none; }

.footer__nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 20px;
  padding-left: 0; }

.footer__nav-wrapper {
  display: flex;
  flex-direction: column; }

.footer__nav-link {
  color: white;
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 5px; }
  .footer__nav-link:hover {
    color: white;
    text-decoration: none; }

.footer__wrapper-bottom {
  color: white; }

.footer__wrapper-bottom-inner {
  display: flex;
  justify-content: flex-end; }

.footer__signature-link, .footer__signature-link:hover {
  text-decoration: none;
  font-size: 18px;
  font-weight: 300;
  color: #fff; }

.footer__contacts {
  color: white;
  display: flex;
  flex-direction: column; }

.footer__contacts-title, .footer__social-title {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 5px;
  letter-spacing: 0.03em; }

.footer__tel, .footer__email {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px; }
  .footer__tel i, .footer__email i {
    font-size: 20px; }
  .footer__tel a, .footer__email a {
    color: white;
    font-size: 18px;
    font-weight: 300;
    padding-left: 10px; }
  .footer__tel a:hover, .footer__email a:hover {
    color: white;
    font-size: 18px;
    text-decoration: none; }

.footer__social-title {
  padding-bottom: 5px; }

.footer__social-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 15px 15px 0; }
  .footer__social-wrapper a {
    display: flex;
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    text-decoration: none;
    margin-right: 15px; }
    .footer__social-wrapper a:last-of-type {
      margin-right: 0; }
  .footer__social-wrapper i {
    color: #fff;
    font-size: 19px; }

.footer__social--fb:hover {
  border: 2px solid #4267b2; }
  .footer__social--fb:hover i {
    color: #4267b2; }

.footer__social--vk:hover {
  border: 2px solid #2787F5; }
  .footer__social--vk:hover i {
    color: #2787F5; }

.footer__social--wa:hover {
  border: 2px solid #25d366; }
  .footer__social--wa:hover i {
    color: #25d366; }

.footer__social--yt:hover {
  border: 2px solid #c4302b; }
  .footer__social--yt:hover i {
    color: #c4302b; }

.footer__social--ig:hover {
  border: 2px solid deeppink; }
  .footer__social--ig:hover i {
    color: deeppink; }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .footer__logo-text {
    font-size: 20px; }
  .footer__logo-description {
    font-size: 14px; }
  .footer__nav {
    flex-direction: column;
    padding-top: 0;
    padding-left: 20px;
    font-size: 16px; }
  .footer__nav-link {
    padding-bottom: 2px; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .footer__wrapper-top-inner-wrapper {
    display: flex;
    justify-content: space-evenly; }
  .footer__contacts {
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 10px;
    padding-bottom: 10px; } }

@media screen and (min-width: 320px) and (max-width: 575px) {
  .footer__nav {
    flex-direction: column;
    padding-left: 15px;
    padding-top: 10px; }
  .footer__nav-link {
    font-size: 18px; } }

@font-face {
  font-family: "roboto-df";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot");
  src: url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.woff") format("woff"), url("../fonts/RobotoRegular/roboto-v20-latin_cyrillic-regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot");
  src: url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.woff") format("woff"), url("../fonts/RobotoBold/roboto-v20-latin_cyrillic-700.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot");
  src: url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.woff") format("woff"), url("../fonts/RobotoExtraBold/roboto-v20-latin_cyrillic-900.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot");
  src: url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.woff") format("woff"), url("../fonts/RobotoSemiBold/roboto-v20-latin_cyrillic-500.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot");
  src: url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.woff") format("woff"), url("../fonts/RobotoLight/roboto-v20-latin_cyrillic-300.ttf") format("truetype"); }

@font-face {
  font-family: "roboto-df";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot");
  src: url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.woff") format("woff"), url("../fonts/RobotoThin/roboto-v20-latin_cyrillic-100.ttf") format("truetype"); }
