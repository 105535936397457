@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";

.footer {
  -webkit-font-smoothing: subpixel-antialiased;
  //background: linear-gradient(180deg, rgba(199,226,234,0.9) 0%, rgba(15,48,93,1) 20%, rgba(15,48,93,1) 100%);

  background-color: #0f305d;
  //background-color: $deepblue;
  padding: 0 0 5px 0;
  //text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.99);

}

.wave-top {
  position: relative;

}
.wave-top::before,
.wave-top::after {
  border-bottom: 5px solid #0f305d;
}

.wave-top::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  background-size: 20px 40px;
  background-image: radial-gradient(circle at 10px -15px, transparent 20px, #0f305d 21px);
}

.wave-top::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15px;
  background-size: 40px 40px;
  background-image: radial-gradient(circle at 10px 26px, #0f305d 20px, transparent 21px);
}


.footer__wrapper {
  padding-top: 20px;
}

.footer__wrapper-top {
}

.footer__wrapper-top-inner-wrapper {
}

.footer__logo-wrapper {
  padding-bottom: 10px;
}

.footer__logo-text {
  color: white;
  font-size: 25px;
  font-weight: 500;
  //text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.99);
  letter-spacing: 0.03em;

}

.footer__logo-description {
  color: white;
  font-size: 16px;
  font-weight: 300;
  //text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.99);
  padding-top: 5px;
}

.footer__location {
  color: white;
  //text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.99);

  &-title {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 5px;
    letter-spacing: 0.03em;
  }
  &-text {
    font-size: 16px;
    font-weight: 300;
    padding-bottom: 5px;
  }
  &-link {
    display: flex;
    font-size: 16px;
    font-weight: 300;
    color: goldenrod;
    padding-bottom: 5px;
  }

  &-link:hover {
    font-size: 16px;
    color: goldenrod;
    text-decoration: none;
  }
}

.footer__nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 20px;
  padding-left: 0;

}

.footer__nav-wrapper {
  display: flex;
  flex-direction: column;
}

.footer__nav-link {
  color: white;
  font-size: 18px;
  //font-size: 16px;
  font-weight: 300;
  //text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.99);
  padding-bottom: 5px;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.footer__wrapper-bottom {
  color: white;
  //text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.99);

}


.footer__wrapper-bottom-inner {
  display: flex;
  justify-content: flex-end;
}

.footer__signature-link, .footer__signature-link:hover {
  text-decoration: none;
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  //text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.99);
}

.footer__contacts {
  color: white;
  display: flex;
  flex-direction: column;

}


.footer__contacts-title, .footer__social-title {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 5px;
  letter-spacing: 0.03em;
  //text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.99);
}

.footer__tel, .footer__email {
  //text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.99);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;

  i {
    font-size: 20px;
  }

  a {
    color: white;
    font-size: 18px;
    font-weight: 300;
    padding-left: 10px;
  }

  a:hover {
    color: white;
    font-size: 18px;
    text-decoration: none;
  }
}

.footer__social-title {
  padding-bottom: 5px;
}

.footer__social-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 15px 15px 0;

  a {
    display: flex;
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    //border: 2px solid #818181;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    text-decoration: none;
    margin-right: 15px;


    &:last-of-type {
      //margin-left: 15px;
      margin-right: 0;
    }

  }

  i {
    color: #fff;
    //color: #818181;
    font-size: 19px;
  }

}


.footer__social--fb:hover {

  border: 2px solid $fb;

  i {
    color: $fb;
  }
}

.footer__social--vk:hover {

  border: 2px solid $vk;

  i {
    color: $vk;
  }
}

.footer__social--wa:hover {

  border: 2px solid $wa;

  i {
    color: $wa;
  }
}

.footer__social--yt:hover {

  border: 2px solid $yt;

  i {
    color: $yt;
  }
}

.footer__social--ig:hover {

  border: 2px solid deeppink;

  i {
    color: deeppink;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .footer__logo-text {
    font-size: 20px;
  }

  .footer__logo-description {
    font-size: 14px;
  }

  .footer__nav {
    flex-direction: column;
    padding-top: 0;
    padding-left: 20px;
    font-size: 16px;
  }

  .footer__nav-link {
    padding-bottom: 2px;
  }

}


@media screen and (min-width: 576px) and (max-width: 767px) {

  .footer__wrapper-top-inner-wrapper {
    display: flex;
    justify-content: space-evenly;
  }

  .footer__contacts {
    flex-direction: row;
    justify-content: space-evenly;

    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 575px) {

.footer__nav {
  flex-direction: column;
  padding-left: 15px;
  padding-top: 10px;

}
  .footer__nav-link {
    //padding-left: 30px;
    font-size: 18px;

  }

}
//
//@media screen and (min-width: 575px) and (max-width: 767px) {
//
//}
//
//@media screen and (min-width: 768px) and (max-width: 991px) {
//
//
//}
//
//@media screen and (min-width: 992px) and (max-width: 1199px) {
//
//
//}