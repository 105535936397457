@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";


.animal {
  padding-top: 25px;
  padding-bottom: 25px;
}

.animal__wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.animal_180 {
  flex-direction: row-reverse;
}


.animal__description {
  -webkit-font-smoothing: antialiased;
}

.animal__title {
  margin: 0;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 20px;
}

.animal__text {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.4;
}

.animal__carousel-item {
  img {
    //object-fit: contain;
    object-fit: cover;
    height: 400px;
    display: block;
    //filter: brightness(90%);
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {

  .animal__text {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .animal__text {
    font-size: 18px;
  }
}

@media screen and (max-width: 575px) {

  .animal {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .animal__text {
    font-size: 18px;
    padding-bottom: 20px;
  }

  .animal__carousel, .animal__carousel-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}