@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";

.bathhouse {
  padding-top: 30px;
  padding-bottom: 30px;
}

.bathhouse__wrapper {
  display: flex;
  justify-content: center;
}

.bathhouse__text {
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  line-height: 1.55;
  font-weight: 300;

}

.bathhouse__link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}


.bathhouse__carousel-item {
  img {
    object-fit: cover;
    height: 400px;
    display: block;
    //filter: brightness(90%);
  }
}

.bathhouse__cta {
  -webkit-font-smoothing: antialiased;
  padding-top: 30px;
  font-size: 22px;
  line-height: 1.55;
  font-weight: 300;
  text-align: center;
}


@media screen and (min-width: 992px) and (max-width: 1200px) {

.bathhouse__cta {
  font-size: 20px;
  padding-left: 0;
  padding-right: 0;
}

}

@media screen and (min-width: 768px) and (max-width: 991px) {


  .bathhouse__text {
    padding-bottom: 20px;
  }

  .bathhouse__cta {
    font-size: 20px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {

  .bathhouse__text {
    font-size: 20px;
    padding-bottom: 20px;
  }

  .bathhouse__cta {
    font-size: 18px;
    padding-left: 0;
    padding-right: 0;
  }

  .bathhouse__carousel-item img {
    height: 300px;
  }

}


@media screen and (max-width: 575px) {

  .bathhouse__text {
    font-size: 17px;
    padding-bottom: 20px;
  }

  .bathhouse__cta {
    font-size: 17px;
    padding-left: 0;
    padding-right: 0;
  }

  .bathhouse__carousel-item img {
    height: 300px;
  }
}