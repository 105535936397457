@import "partials/mixins.scss";
@import "partials/fonts.scss";
@import "partials/vars.scss";


.contacts {
  padding-top: 30px;
  padding-bottom: 30px;
}

.contacts__wrapper {
  display: flex;
  justify-content: center;
}


.contacts__title {
  color: black;
  font-weight: 600;
  font-size: 35px;
  line-height: 1.23;
  text-align: center;
  padding-bottom: 15px;
}

.contacts__title--pt {
  padding-top: 20px;
}

.contacts__mail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contacts__telephone {
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.fa-phone, .fa-mail {
  font-size: 20px;
}

.contacts__telephone-link, .contacts__telephone-link:hover {
  text-decoration: none;
  font-size: 20px;
  font-weight: 300;
  color: black;
  padding-left: 15px;
}

.contacts__mail-link, .contacts__mail-link:hover {
  text-decoration: none;
  font-size: 20px;
  font-weight: 300;
  color: black;
  padding-left: 15px;
}

.contacts__location {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  a {
    align-items: center;
  }
}

.contacts__location-link, .contacts__location-link:hover {

  font-size: 20px;
  font-weight: 300;
}

@media screen and (max-width: 575px) {

  .contacts__title {
    font-size: 26px;
  }

  .contacts__telephone {
    justify-content: center;
  }
  .contacts__mail {
    justify-content: center;
  }

  .contacts__location-link,
  .contacts__location-link:hover {
    text-align: center;
  }

  }